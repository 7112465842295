export default {
    de: {
        notifications: {
            notification_center: {
                titel: 'Notification Center',
                text: 'In unserem neuen Notification Center werden wir Sie ab sofort auf neue Funktionen und Benachrichtigungen im Application Center hinweisen.'
            },
            ui_customization: {
                titel: 'Einstellungen Oberfläche',
                text: 'Wir bieten ab sofort die Möglichkeit, das Application Center im Dark Mode sowie in der ganzen Breite des Screens zu verwenden.'
            },
            table_config: {
                titel: 'Neue Tabellenoptionen',
                text: 'Für eine bessere Übersicht in Tabellen können Tabellenspalten nun individuell ein- und ausgeblendet werden. Dabei ist zu beachten, dass diese Einstellung vorerst nur im Browser gespeichert wird.'
            },
            feedback: {
                titel: 'Ihre Meinung ist uns sehr wichtig',
                text: 'Wir hoffen, dass Sie mit unserem Service zufrieden sind! Ihre Meinung ist uns sehr wichtig, und wir möchten uns kontinuierlich verbessern. Können Sie sich einen Moment Zeit nehmen, um uns Ihr Feedback zu geben? Klicken Sie <a target="__blank" class="px-0" rel="external nofollow" href="https://survey-eu1.hsforms.com/1JzFKHeq3QcyQfls6Rto0FQf2v0q">hier</a>, um an unserer kurzen Umfrage teilzunehmen. Vielen Dank für Ihre Unterstützung!'
            },
            protection_cluster_control: {
                titel: 'Neues Feature in Cluster Control',
                text: 'Das cluster-control Feature Protection ist jetzt verfügbar. Damit können einzelne IP-Adressen oder IP- Ranges auf die Block- und Allowlist hinzugefügt oder entfernt werden. Die Listen lassen sich tabellarisch ausgeben.'
            }
        },
        link: 'Anzeigen',
        feedback_link: 'Zur Umfrage'
    },
    en: {
        notifications: {
            notification_center: {
                titel: 'Notification Center',
                text: 'Our new Notification Center will keep you informed about new features and notifications in the Application Center.'
            },
            ui_customization: {
                titel: 'Display options',
                text: 'We now offer the option to use the Application Centre in dark mode and across the full width of the screen.'
            },
            table_config: {
                titel: 'New table options',
                text: 'For a better overview in tables, you can now show and hide table columns individually. Note that this setting is initially only saved in the browser.'
            },
            feedback: {
                titel: 'Your opinion is very important to us!',
                text: 'We hope you are happy with our service! Your opinion is very important to us and we are always looking to improve. Can you take a moment to give us your feedback? Click <a class="px-0" target="__blank" rel="external nofollow" href="https://survey-eu1.hsforms.com/1IGkVqUQpRi-cC3ypdaBDZwf2v0q">here</a> to take part in our short survey. Thank you for your help!'
            },
            protection_cluster_control: {
                titel: 'New feature in Cluster Control',
                text: 'The cluster-control feature Protection is now available. This allows individual IP addresses or IP ranges to be added to or removed from the block and allow lists. The lists can be displayed in tabular form.'
            }
        },
        link: 'Show',
        feedback_link: 'To the survey'
    }
};
