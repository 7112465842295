<template>
    <div :id="pageId" class="outerWrapper">
        <div id="content" :class="containerClass">
            <TopBar :languages="languages" ref="topBar" v-if="!maintenance"/>
            <template v-if="notFound || notAllowed || maintenance">
                <MaintenancePage class="mt-4" v-if="maintenance" />
                <NotFoundPage :full-width="containerClass === 'container-fluid' || !user.isLoggedIn" class="mt-4" hide-logo v-if="notFound" />
                <NotAllowedPage :full-width="containerClass === 'container-fluid' || !user.isLoggedIn" class="mt-4" hide-logo v-if="notAllowed">
                    <template v-if="errorPageMessage">
                        <p class="lead" v-html="$t(errorPageMessage)"></p>
                    </template>
                </NotAllowedPage>
            </template>
            <div class="main-content" v-else>
                <router-view />
            </div>
        </div>
        <FooterBar :container-class="containerClass" />
        <div class="global-alert">
            <AtomAlert :variant="alert.variant" :show="alert.show" v-for="(alert, index) in alerts" :key="`app-notify-${index}`" :dismissible="alert.dismissible">
                <span v-html="alert.message"></span>
            </AtomAlert>
        </div>
        <template v-if="activeDemo===true">
            <div @click="contactDemoModal=true" class="toggleDemoModal" v-tippy="$t('tour.get_advice')">
                <AtomIcon class="demoIcon p-3" name="email-line" />
            </div>
            <DemoContact :show="contactDemoModal" @close="contactDemoModal = false"/>
        </template>
        <MoleculeArrowUp v-else />
    </div>
</template>
<script>
    import TopBar from "@/components/TopBar";
    import FooterBar from "@/components/FooterBar";
    import NotFoundPage from "@/pages/Errors/NotFoundPage.vue";
    import NotAllowedPage from "@/pages/Errors/NotAllowedPage.vue";
    import MaintenancePage from "@/pages/Errors/MaintenancePage.vue";
    import {EventHandler, ResponseService, StateChangeService, userStore} from '@development/cluster-center';
    import DemoContact from "./components/DemoContact.vue";
    import { provide,ref } from "vue";
    import { initIubendaCookieBanner } from '@/composables/iubenda';
    import {share} from "pinia-shared-state";
    import { useColorMode } from 'bootstrap-vue-next';
    import { useColorScheme } from './composables/useColorScheme';
    import { browserSupport } from './composables/browserSupport';

    export default {
        components: {
            FooterBar,
            TopBar,
            NotFoundPage,
            NotAllowedPage,
            DemoContact,
            MaintenancePage
        },
        data() {
            return {
                languages: [{
                    name: 'de',
                    active: false,
                }, {
                    name: 'en',
                    active: false,
                }],
                alerts: [],
                notFound: false,
                notAllowed: false,
                maintenance: false,
                url: process.env.VUE_APP_BASE_URL + '/orders',
                errorPageMessage: null,
                user: null,
                contactDemoModal: false,
                activeDemo: window.Config.isDemo(),
                containerClass: 'wrapped',
                darkMode: false,
                lastPage: '',
                conatinerUnshare: null,
                darkModeUnshare: null,
                clientBrowser: {
                    isSupported: true
                }
            };
        },
        methods: {
            prepareLanguages() {
                document.querySelector('html').setAttribute('lang', this.$i18n.locale);

                let active = false;

                this.languages.forEach(language => {
                    language.active = false;

                    if (language.name === this.$i18n.locale) {
                        language.active = true;
                        active = true;
                    }
                });

                if (!active) {
                    this.languages[0].active = true;
                }
            },
            setColorMode() {
                let target = document.querySelector('html');

                let mode = useColorMode({
                    selector: target,
                });

                if (this.user.forcedMode === false) {
                    this.user.darkMode = this.browserColorMode.colorScheme === 'dark' ? true : false;
                }

                if (this.user.darkMode === true && !window.Config.isDemo()) {
                    mode.value = 'dark';
                } else {
                    mode.value = '';
                }
            }
        },
        setup() {
            const browserColorMode = ref(useColorScheme());

            return {
                browserColorMode
            };
        },
        created() {
            provide('$app', this.$app);
            this.user = userStore();

            if (window.Config.isDemo()) {
                this.containerClass = 'container';
            }

            if (this.user?.isLoggedIn) {
                this.containerClass = this.user.container === 'wrapped' ? 'container' : 'container-fluid';
            } else {
                this.containerClass = 'container-fluid';
            }

            this.$i18n.locale = this.user.language;
            this.$root.$i18n.locale = this.user.language;

            this.prepareLanguages();

            if (window.Config.isDemo()) {
                return;
            }

            if (this.user.isLoggedIn && this.user.id === 0) {
                this.user.logout();
            }

            this.user.refreshToken();
        },
        mounted() {
            if (!window.StateChangeHandler) {
                window.StateChangeHandler = new StateChangeService(this.$i18n);
            }      

            this.$app.i18n = window.Config.get('i18n');
            window.Config.set('cc', this.$cc);

            this.clientBrowser = browserSupport();

            if (!this.clientBrowser.isSupported && this.$app.alerts.disable_browser_support_alert === false) {
                setTimeout(() => {
                    ResponseService.warning(window.Config.get('i18n').t('errors.not_supported_browser'), false, 'noSupport');
                }, 1500);
            }

            if (this.activeDemo) {
                provide('demoConfig', {
                    useKeyboardNavigation: false,
                    labels: {
                        buttonSkip: this.$i18n.t('tour.skip'),
                        buttonNext: this.$i18n.t('tour.next'),
                        buttonStop: this.$i18n.t('tour.finish')
                    },
                    enabledButtons: {
                        buttonNext: true,
                        buttonPrevious: false,
                        buttonSkip: true,
                        buttonStop: true
                    },
                    highlight: true
                });
            }

            if (this.user?.isLoggedIn) {
                this.setColorMode();
            }

            window.addEventListener('app:updated:locale', () => {
                this.prepareLanguages();
            });

            window.addEventListener('app:maintenance', () => {this.maintenance = true;});
            window.addEventListener('app:not-found', () => {this.notFound = true;});
            window.addEventListener('app:not-allowed', payload => {
                this.notAllowed = true;

                if (Array.isArray(payload?.detail)) {
                    this.errorPageMessage = payload?.detail[1];
                }
            });

            window.addEventListener('auth:logout', () => {

                setTimeout(() => {
                    let target = document.querySelector('html');

                    let mode = useColorMode({
                        selector: target,
                    });

                    mode.value = '';
                }, 500);

                this.user.reset();
                ResponseService.danger(window.Config.get('i18n').t('errors.logout'), true, 'logout');
                this.$router.push(this?.$cc?.auth?.logout_redirect || {name: 'login'});
            });

            window.addEventListener('app:notify:delete', event => {
                let id = event.detail.id;
                let existingAlert = this.alerts.find(a => a.id === id);

                if (!existingAlert) {
                    return;
                }

                this.alerts.splice(this.alerts.indexOf(existingAlert), 1);
            });

            window.addEventListener('app:notify', event => {
                let dismissibleTypes = ['warning', 'danger'];
                let show = true;
                let id = event.detail.id;
                let existingAlert = this.alerts.find(a => a.id === id);

                if (existingAlert) {
                    show = false;
                }

                this.alerts.push({
                    show: show,
                    message: event.detail.message || '',
                    variant: event.detail.type || 'info',
                    id: id,
                    dismissible: dismissibleTypes.includes(event.detail.type || 'info')
                });

                const alertConfig = this.$app.alerts;

                if (alertConfig.autoclose_types.includes(event.detail.type)) {
                    let alert = this.alerts.find(a => a.id === id);
                    let autoclose = event.detail.message.split(' ').length < 20 ? alertConfig.autoclose.short : alertConfig.autoclose.long;

                    setTimeout(() => {
                        alert.show = false;
                    }, autoclose);
                }
            });

            if (window.Config.isDemo()) {
                initIubendaCookieBanner();
            }

            const { unshare: containerUnshare } = share('container', this.user, { initialize: true });
            const { unshare: darkModeUnshare } = share('darkMode', this.user, { initialize: true });

            this.conatinerUnshare = containerUnshare;
            this.darkModeUnshare = darkModeUnshare;

            this.user.$subscribe((mutation, state) => {
                let target = document.querySelector('html');

                if (this.user?.isLoggedIn) {
                    this.darkMode = state.darkMode;
                    let mode = useColorMode({
                        selector: target,
                    });

                    mode.value = this.darkMode === true ? 'dark' : '';

                    this.containerClass = state.container === 'wrapped' ? 'container' : 'container-fluid';
                }
            });

            EventHandler.initGlobalEvents();
        },
        computed: {
            pageId() {
                return `page-${this.$route.name}`;
            }
        },
        watch: {
            '$route' (to) {
                this.notFound = false;
                this.notAllowed = false;
                                  
                if (this.lastPage === 'login') {
                    if (to.name === 'dashboard') {
                        setTimeout(() => {
                            this.setColorMode();
                        }, 1000);
                    }
                } 


                this.lastPage = to.name; 

                document.title = to.meta.title || '';

                if (window.Config.isDemo()) {
                    return;
                }

                this.user.refreshToken();
            }
        },
        beforeUnmount() {
            try {
                if (typeof this.containerUnshare === 'function') {
                    this.conatinerUnshare();
                }

                if (typeof this.darkModeUnshare === 'function') {
                    this.darkModeUnshare();
                }
            } catch {}
        }
    };
</script>
<style lang="scss">
    #app {
        overflow: auto;
        scroll-behavior: smooth;
    }

    #content {
        flex: 1 0 auto;
        transition: max-width ease-in-out .2s;

        &.container-fluid {
            max-width: 100%;
        }
    }

    #app,
    [id^=page-] {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .global-alert {
        position: fixed;
        top: 80px;
        right: 40px;
        z-index: 1056;
        max-width: 500px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;

        .alert-text {
            padding: 0 1rem;
        }
    }
</style>
<style lang="scss" scoped>
    $base-factor: 1.5;
    $base-animation-time: 2.5s;
    $gear-small-size: 75px;
    $gear-big-size: $gear-small-size * $base-factor;

    #maintenanceSvg {
        .loader {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: $gear-small-size / 2 * $base-factor;
        }

        .icon {
            width: 125px;
            height: 125px;
            fill: transparent;
            stroke: $secondary;
        }

        .small {
            width: $gear-small-size;
            height: $gear-small-size;

        }

        .big {
            width: $gear-big-size;
            height: $gear-big-size;
            position: relative;
            left: -$gear-small-size / 2.6;
            top: $gear-small-size / 1.9 * $base-factor;
        }

    }

    .toggleDemoModal {
        background-color: $primary;
        color: $white;
        border-radius: 100%;
        cursor: pointer;
        position: fixed !important;
        bottom: 5%;
        right: 2%;
        height: auto;
        z-index: 1000;
        transition: all .15s ease;

        .demoIcon {
            font-size: 1.5rem;
            color: $white;
        }

        &:hover {
            bottom: 5.3%;
        }
    }
</style>