import ManagedDashboard from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedDashboard.vue";
import ManagedApache from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Apache/ManagedApache.vue";
import ManagedNginx from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Nginx/ManagedNginx.vue";
import ManagedProfiler from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Profiler/ManagedProfiler.vue";
import ManagedSsh from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Ssh/ManagedSsh.vue";
import ManagedSupervisor from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Supervisor/ManagedSupervisor.vue";
import ManagedVarnishSsl from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/VarnishSSL/ManagedVarnishSsl.vue";
import ManagedRedis from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/Redis/ManagedRedis.vue";
import ManagedElasticsearch from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Elasticsearch/ManagedElasticsearch.vue";
import ManagedMysql from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/MySQL/ManagedMysql.vue";
import ManagedOpenSearch from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/OpenSearch/ManagedOpenSearch.vue";
import ManagedShopSecurity from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/ShopSecurity/ManagedShopSecurity.vue";
import ManagedShopSecurityReport from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/ShopSecurity/ShopSecurityReport.vue";
import ManagedClusterControl from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedClusterControl.vue";
import ManagedShopPerformance from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ShopPerformance/ManagedShopPerformance.vue";
import ManagedShopPerformanceReport from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ShopPerformance/ShopPerformanceReport.vue";
import ManagedMonitoring from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Monitoring/ManagedMonitoring.vue";
import ManagedBackups from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Backups/ManagedBackups.vue";
import ManagedPackageUpdates from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedPackageUpdates.vue";


export default [
    {
        path: "/",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "Demo" */ "@/pages/Demo/DemoPage.vue"),
        meta: {
            middleware: ['isDemo'],
            title: "Dashboard",
        }
    },
    {
        path: "/clusters/:clusterId/managed",
        name: 'managed-cluster',
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['isDemo'],
            title: 'ManagedCenter - Dashboard'
        },
        props: {
            activeComponent: ManagedDashboard
        }
    }, {
        path: "/clusters/:clusterId/servers/:serverId/managed",
        name: 'managed-root',
        children: [{
            path: '',
            name: "managed-dashboard",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Dashboard'
            },
            props: {
                activeComponent: ManagedDashboard
            }
        }, {
            path: 'apache',
            name: "managed-apache",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Apache'
            },
            props: {
                activeComponent: ManagedApache
            },
            children: [{
                path: 'domains/create',
                name: "managed-apache-new-domain",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - New Domain'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'domains/:hostname',
                name: "managed-apache-edit-domain",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Edit'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'logs/access',
                name: "managed-apache-logs-access",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Access Log'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'logs/access/:host',
                name: "managed-apache-logs-access-selected",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Access Log'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'logs/error',
                name: "managed-apache-logs-error",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Error Log'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'logs/error/:host',
                name: "managed-apache-logs-error-selected",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Error Log'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'redirects/create',
                name: "managed-apache-create-redirect",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Weiterleitung erstellen'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'redirects/:hostname',
                name: "managed-apache-edit-redirect",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Weiterleitung bearbeiten'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'authentications/create',
                name: "managed-apache-create-authentication",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Passwortschutz erstellen'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }, {
                path: 'authentications/:hostname',
                name: "managed-apache-edit-authentication",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Apache - Passwortschutz bearbeiten'
                },
                props: {
                    activeComponent: ManagedApache
                }
            }]
        }, {
            path: 'nginx',
            name: "managed-nginx",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - NGINX'
            },
            props: {
                activeComponent: ManagedNginx
            },
            children: [{
                path: 'domains/create',
                name: "managed-nginx-new-domain",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - New Domain'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'domains/:hostname',
                name: "managed-nginx-edit-domain",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Edit'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'logs/access',
                name: "managed-nginx-logs-access",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Access Log'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'logs/access/:host',
                name: "managed-nginx-logs-access-selected",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Access Log'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'logs/error',
                name: "managed-nginx-logs-error",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Error Log'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'logs/error/:host',
                name: "managed-nginx-logs-error-selected",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Error Log'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'redirects/create',
                name: "managed-nginx-create-redirect",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Weiterleitung erstellen'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'redirects/:hostname',
                name: "managed-nginx-edit-redirect",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - Weiterleitung bearbeiten'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }, {
                path: 'config',
                name: "managed-nginx-config",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - NGINX - NGINX.conf'
                },
                props: {
                    activeComponent: ManagedNginx
                }
            }]
        }, {
            path: 'profiler',
            name: "managed-profiler",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Profiler'
            },
            props: {
                activeComponent: ManagedProfiler
            }
        }, {
            path: 'ssh',
            name: "managed-ssh",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - SSH'
            },
            props: {
                activeComponent: ManagedSsh
            },
            children: [{
                path: 'key/create',
                name: "managed-ssh-new-key",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - SSH - New Key'
                },
                props: {
                    activeComponent: ManagedSsh
                }
            }]
        }, {
            path: 'supervisor',
            name: "managed-supervisor",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Supervisor'
            },
            props: {
                activeComponent: ManagedSupervisor
            },
            children: [{
                path: 'logs',
                name: "managed-supervisor-logs",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Supervisor - Logs'
                },
                props: {
                    activeComponent: ManagedSupervisor
                }
            }]
        }, {
            path: 'varnish-ssl',
            name: "managed-varnish-ssl",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Varnish SSL'
            },
            props: {
                activeComponent: ManagedVarnishSsl
            },
            children: [{
                path: 'config',
                name: "managed-varnish-ssl-config",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Varnish SSL - Konfiguration'
                },
                props: {
                    activeComponent: ManagedVarnishSsl
                }
            }, {
                path: 'logs',
                name: "managed-varnish-ssl-logs",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Varnish SSL - Logs'
                },
                props: {
                    activeComponent: ManagedVarnishSsl
                }
            }, {
                path: 'startparameter',
                name: "managed-varnish-ssl-startparameter",
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - Varnish SSL - Startparameter'
                },
                props: {
                    activeComponent: ManagedVarnishSsl
                }
            }]
        }, {
            path: 'redis',
            name: "managed-redis",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Redis'
            },
            props: {
                activeComponent: ManagedRedis
            }
        }, {
            path: 'elasticsearch',
            name: "managed-elasticsearch",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Elasticsearch'
            },
            props: {
                activeComponent: ManagedElasticsearch
            }
        }, {
            path: 'mysql',
            name: "managed-mysql",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - MySQL'
            },
            props: {
                activeComponent: ManagedMysql
            }
        }, {
            path: 'open-search',
            name: "managed-open-search",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - OpenSearch'
            },
            props: {
                activeComponent: ManagedOpenSearch
            }
        }, {
            path: 'shop-security',
            name: "managed-shop-security",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - ShopSecurity'
            },
            props: {
                activeComponent: ManagedShopSecurity
            },
            children: [{
                path: 'report/:reportId/',
                name: 'managed-shopsecurity-report',
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - ShopSecurity - Report'
                },
                props: {
                    activeComponent: ManagedShopSecurityReport
                }
            }]
        }, {
            path: 'cluster-control',
            name: "managed-cluster-control",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Cluster Control'
            },
            props: {
                activeComponent: ManagedClusterControl
            }
        }, {
            path: 'shop-performance',
            name: "managed-shop-performance",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - ShopPerformance'
            },
            props: {
                activeComponent: ManagedShopPerformance
            },
            children: [{
                path: 'report/:reportId/',
                name: 'managed-shopperformance-report',
                component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
                meta: {
                    middleware: ['isDemo'],
                    title: 'ManagedCenter - ShopPerformance - Report'
                },
                props: {
                    activeComponent: ManagedShopPerformanceReport
                }
            }]
        }, {
            path: 'monitoring',
            name: "managed-monitoring",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Monitoring'
            },
            props: {
                activeComponent: ManagedMonitoring
            }
        },
        {
            path: 'backups',
            name: "managed-backups",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Backups'
            },
            props: {
                activeComponent: ManagedBackups
            }
        },
        {
            path: 'package-updates',
            name: "managed-package-updates",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['isDemo'],
                title: 'ManagedCenter - Package Updates'
            },
            props: {
                activeComponent: ManagedPackageUpdates
            }
        }]
    }
];