import * as ApiErrorHandler from "@/services/Responses/ErrorHandler/Api";

export const clusterCenterConfiguration = {
    bugsnag: {
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY || '',
        appVersion: process.env.VUE_APP_BUGSNAG_APP_VERSION || '',
        excludes: {
            statusCodes: [422, 404, 403, 401],
        },
        autoTrackSessions: false,
        collectUserIp: false
    },
    auth: {
        logout_redirect: { name: 'login' },
        login: {
            // in minutes
            token_refresh_delay: process.env.VUE_APP_AUTH_TOKEN_REFRESH_DELAY_IN_MINUTES || 15
        }
    },
    advice: {
        email: 'beratung@maxcluster.de',
        phone: '+4952514141350',
        phone_formatted: '+49 5251 4141 350'
    },
    apiErrorHandler: ApiErrorHandler
};
