export const notifications = [
    {
        id: 0,
        title: 'notifications.notifications.notification_center.titel',
        message: 'notifications.notifications.notification_center.text',
        date: '2024-06-05',
        isRead: false,
        link: ''
    },
    {
        id: 1,
        title: 'notifications.notifications.ui_customization.titel',
        message: 'notifications.notifications.ui_customization.text',
        date: '2024-06-05',
        image: {
            de: require('@/assets/images/notifications/custom_design_de.png'),
            en: require('@/assets/images/notifications/custom_design_en.png'),
        },
        isRead: false,
        link: 'profile-personal-data'
    },
    {
        id: 2,
        title: 'notifications.notifications.table_config.titel',
        message: 'notifications.notifications.table_config.text',
        date: '2024-06-05',
        image: {
            de: require('@/assets/images/notifications/table_config_de.png'),
            en: require('@/assets/images/notifications/table_config_en.png'),
        },
        isRead: false,
        link: 'invoices-overview'
    },
    {
        id: 3,
        title: 'notifications.notifications.protection_cluster_control.titel',
        message: 'notifications.notifications.protection_cluster_control.text',
        date: '2024-09-09',
        isRead: false,
        link: ''
    },
    {
        id: 4,
        title: 'notifications.notifications.feedback.titel',
        message: 'notifications.notifications.feedback.text',
        date: '2024-09-12',
        isRead: false,
        link: 'https://survey-eu1.hsforms.com/1JzFKHeq3QcyQfls6Rto0FQf2v0q',
        link_en: 'https://survey-eu1.hsforms.com/1IGkVqUQpRi-cC3ypdaBDZwf2v0q',
        external: true,
        isFeedback: true
    }
];
